import { useMediaQuery } from "usehooks-ts";

import { useFlags } from "../../../Context/FlagsProvider";
import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../store/details-device-store";

export const useDeviceListCard = () => {
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const { hasPrivileges } = useFlags();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  function onAddressClick(lat: number, lng: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDetailsClick(device: GetDevicesActivatedLastReadingsResponse) {
    detailsDeviceActions.setDevice(device);
    detailsDeviceActions.setModalIsOpen(true);
  }

  return {
    onAddressClick,
    onDetailsClick,
    hasPrivileges,
    isMobile,
  };
};
