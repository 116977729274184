import {
  GL_BAR_CHART,
  GL_DEVELOPERS,
  GL_GLStation,
  GL_LAYOUT,
  GL_SEND_MESSAGE,
  GL_SETTINGS,
  GL_SHARE,
  GLAsideItemProps,
  useGLMenuMobile,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../../Context/FlagsProvider";
import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";
import { MessageCenterRoutes } from "../MessageCenterLayout/useMessageCenterLayout";

export enum MainRoutes {
  DASHBOARD = "/dashboard",
  DEVICES = "/devices",
  MESSAGE_CENTER = "/message-center",
  MESSAGE_CENTER_OVERVIEW = "/message-center/overview",
  MESSAGE_LIST = "/message-center/message-list",

  EVENT_LIST = "/network/event-list",

  SETTINGS = "/settings",

  REPORTS = "/reports",

  DEVICE_LIST = "/devices",
  GROUP_LIST = "/devices/groups",
  DEVELOPERS = "/developers",
  APPS = "/developers/apps",
  INTEGRATIONS = "/developers/integration",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { handleOpenMenuMobile, isMenuMobileOpen } = useGLMenuMobile();
  const { hasPrivileges, hasBetaPrivileges, user, flagsAlreadyLoaded } =
    useFlags();

  const navListTop: GLAsideItemProps[] = useMemo(() => {
    const allPages: GLAsideItemProps[] = [
      {
        id: 1,
        Icon: GL_LAYOUT,
        text: "Dashboard",
        isToAppear: hasPrivileges(["show_main_dashboard_page"]),
        active: verifyIfPathIsActive(MainRoutes.DASHBOARD, false),
        dropdownItems: [],
        onClick: () => {
          navigate(MainRoutes.DASHBOARD);
        },
      },
      {
        id: 2,
        Icon: GL_SEND_MESSAGE,
        text: t("messageCenter.title"),
        isToAppear:
          hasPrivileges(["show_message_center_page"]) ||
          hasPrivileges(["show_message_notification_list_page"]),
        active: verifyIfPathIsActive(MainRoutes.MESSAGE_CENTER, false),
        dropdownItems: [
          {
            id: 1,
            text: t("messageCenter.subtitle"),
            isToAppear: hasPrivileges(["show_message_center_page"]),
            onClick: () => navigate(MessageCenterRoutes.ONLINE_CAMPAIGN),
          },
          {
            id: 2,
            text: t("notificationList.subtitle"),
            isToAppear: hasPrivileges(["show_message_notification_list_page"]),
            onClick: () => navigate(MainRoutes.MESSAGE_LIST),
          },
        ],
        onClick: () => {},
      },
      {
        id: 3,
        Icon: GL_SHARE,
        text: t("network.subtitle"),
        active: verifyIfPathIsActive(MainRoutes.EVENT_LIST, false),
        isToAppear: hasPrivileges(["show_network_event_page"]),
        dropdownItems: [
          {
            id: 1,
            isToAppear: true,
            text: t("eventList.subtitle"),
            onClick: () => navigate(MainRoutes.EVENT_LIST),
          },
        ],
        onClick: () => {},
      },
      {
        id: 4,
        Icon: GL_GLStation,
        text: t("deviceList.title"),
        active: verifyIfPathIsActive(MainRoutes.DEVICE_LIST, false),
        isToAppear:
          hasPrivileges(["show_devices_list_page"]) ||
          hasPrivileges(["show_devices_group_page"]),
        dropdownItems: [
          {
            id: 1,
            // Icon: GL_GLStation,
            text: t("deviceList.subtitle"),
            isToAppear: hasPrivileges(["show_devices_list_page"]),
            active: verifyIfPathIsActive(MainRoutes.DEVICE_LIST),
            onClick: () => {
              navigate(MainRoutes.DEVICE_LIST);
            },
          },
          {
            id: 2,
            text: t("groupList.subtitle"),
            // isToAppear: hasBetaPrivileges("beta_client") || hasBetaPrivileges("beta"),
            isToAppear: hasPrivileges(["show_devices_group_page"]),
            active: verifyIfPathIsActive(MainRoutes.GROUP_LIST),
            onClick: () => {
              navigate(MainRoutes.GROUP_LIST);
            },
          },
        ],
        onClick: () => {},
      },
      {
        id: 5,
        Icon: GL_BAR_CHART,
        text: t("pages.reports.title"),
        active: verifyIfPathIsActive(MainRoutes.REPORTS),
        dropdownItems: [],
        isToAppear: hasPrivileges(["show_main_report_page"]),
        onClick: () => {
          navigate(MainRoutes.REPORTS);
        },
      },
      {
        id: 6,
        Icon: GL_DEVELOPERS,
        text: t("developers.title"),
        active: verifyIfPathIsActive(MainRoutes.DEVELOPERS, false),
        isToAppear: hasPrivileges(["show_developer_apps_page"]),
        dropdownItems: [
          {
            id: 1,
            text: t("pages.settings.apps"),
            isToAppear: hasPrivileges(["show_developer_apps_page"]),
            onClick: () => navigate(MainRoutes.APPS),
          },
          // {
          //   id: 2,
          //   text: t("integrations.title"),
          //   isToAppear:
          //     hasBetaPrivileges() &&
          //     !hasPrivileges(["organization_group_admin_get"]),
          //   onClick: () => navigate(MainRoutes.INTEGRATIONS),
          // },
        ],
        onClick: () => {},
      },
    ];

    return allPages
      .filter((item) => {
        if (item.isToAppear) return item;
      })
      .map((item) => {
        if (item.dropdownItems?.length) {
          const newItem = {
            ...item,
            dropdownItems: item.dropdownItems.filter((dropdownItem) => {
              if (dropdownItem.isToAppear) return dropdownItem;
            }),
          };

          return newItem;
        }

        return item;
      });
  }, [hasPrivileges, hasBetaPrivileges, user, flagsAlreadyLoaded]);

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 4,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      isToAppear:
        hasPrivileges(["show_settings_overview_page"]) ||
        hasPrivileges(["show_settings_my_account_page"]) ||
        hasPrivileges(["show_settings_login_security_page"]) ||
        hasPrivileges(["show_settings_users_page"]) ||
        hasPrivileges(["show_settings_audit_page"]) ||
        hasPrivileges(["show_settings_user_group_page"]),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  const removeStyle = () => {
    const container = document.querySelector(".page-animated");
    if (container) {
      container.removeAttribute("style");
    }
  };

  useEffect(() => {
    if (isMenuMobileOpen) {
      handleOpenMenuMobile();
    }

    removeStyle();
  }, [navigate]);

  return { navListTop, navListBottom };
}
