import { GLSettingsGroupsUsers } from "@group-link-one/grouplink-components";

import { GroupUsersTransferList } from "./TransferList/GroupUsersTransferList";
import { useOrganizationGroups } from "./useOrganizationGroups";

export const GroupsUsersPage = () => {
  const {
    groups,
    listGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    hasPrivileges,
  } = useOrganizationGroups();

  return (
    <GLSettingsGroupsUsers
      groups={groups}
      transferListContent={<GroupUsersTransferList />}
      hasPrivilegeToCreate={hasPrivileges(["action_btn_new_user_group"])}
      onListGroups={listGroups}
      onCreateGroup={createGroup}
      onUpdateGroup={updateGroup}
      onDeleteGroupById={deleteGroup}
    />
  );
};
