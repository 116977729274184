import {
  IApp,
  IAppAndroidVault,
  IAppData,
  IAppIosVault,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";

import { useFlags } from "../../../Context/FlagsProvider";
import { useAppService } from "../../../Services/appService/useAppService";

export const useApp = () => {
  const { createApp, getApps, countApps, deleteApps, appsVault } =
    useAppService();

  const { hasPrivileges } = useFlags();
  const [androidOpen, setAndroidOpen] = useState<boolean>(false);
  const [iosOpen, setIosOpen] = useState<boolean>(false);
  const { addToast } = useToast();
  const { t } = useI18n();

  const [androidSelected, setAndroidSelected] = useState<boolean>(false);
  const [iosSelected, setIosSelected] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const registerApp = async (data: IAppData) => {
    const { name, android, ios } = data;

    const appData: IApp = {
      android: android.android,
      ios: ios.ios,
      name: name,
      pkg_name_android:
        (android.android && android.pkg_name_android) || undefined,
      pkg_name_ios: (ios.ios && ios.pkg_name_ios) || undefined,
    };

    const app = await createApp(appData);

    appData.id = app.data.id;

    if (android.android && ios.ios) {
      await Promise.all([
        proccessIos(app.data.id, { ios }),
        proccessAndroid(app.data.id, { android }),
      ]);
    }

    if (ios.ios) await proccessIos(app.data.id, { ios });

    if (android.android) await proccessAndroid(app.data.id, { android });

    const previousApps = queryClient.getQueryData<IApp[]>(["get-apps"]);
    const previousCount = queryClient.getQueryData<number>(["count-apps"]);

    if (previousApps) {
      queryClient.setQueryData<IApp[]>(
        ["get-apps"],
        [appData, ...previousApps]
      );
    }

    queryClient.setQueryData<number>(["count-apps"], previousCount ?? 0 + 1);

    return app;
  };

  const { data: apps, isLoading } = useQuery({
    queryKey: ["get-apps"],
    queryFn: () => {
      if (!hasPrivileges(["apps_get"])) return;

      return getApps().then((res) => res);
    },
  });

  const { data: count } = useQuery({
    queryKey: ["count-apps"],
    queryFn: () => {
      if (!hasPrivileges(["apps_get"])) return;

      return countApps().then((res) => res);
    },
  });

  const countAppsObserve = useMemo(() => {
    return count;
  }, [count]);

  const deleteApp = async (appId: number) => {
    const previousCount = queryClient.getQueryData<number>(["count-apps"]);

    queryClient.setQueryData(["get-apps"], (value: IApp[]) => {
      return value.filter((app) => app.id !== appId);
    });

    if (previousCount) {
      queryClient.setQueryData(["count-apps"], previousCount - 1);
    }

    return deleteApps(appId);
  };

  const proccessIos = async (
    appId: number,
    iosData: Pick<IAppData, "ios">
  ): Promise<void> => {
    const reader = new FileReader();

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const data = event.target?.result as string;

      await appInVault(
        appId,
        {
          key_id: iosData.ios.key_id!,
          team_id: iosData.ios.team_id!,
          private_key: data,
          sandbox: false,
        },
        "apn_identity"
      );
    };

    reader.readAsText(iosData.ios.p8_key_file);

    return;
  };

  const proccessAndroid = async (
    appId: number,
    androidData: Pick<IAppData, "android">
  ): Promise<void> => {
    const reader = new FileReader();

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const credentials = event.target?.result as string;

      await appInVault(
        appId,
        {
          credentials_json: credentials,
          sender_id: androidData.android.sender_id!,
        },
        "gcm_identity"
      );
    };

    reader.readAsText(androidData.android.credentials_json);

    return;
  };

  const appInVault = async (
    appId: number,
    data: IAppAndroidVault | IAppIosVault,
    type: string
  ): Promise<void> => {
    await appsVault({
      namespace: `app:${appId}`,
      data,
      type,
    });
    return;
  };

  const onRegisterApp = async (data: IAppData, callback: () => void) => {
    await registerApp(data)
      .then(() => {
        addToast({
          title: t("overview.apps.messages.appCreated"),
          message: t("overview.apps.messages.appCreatedDescription"),
          type: "success",
        });
        setAndroidOpen(false);
        setIosOpen(false);
        setAndroidSelected(false);
        setIosSelected(false);

        callback();
        return;
      })
      .catch((err: unknown) => {
        const error = err as AxiosError<Error>;
        const { response } = error;
        if (response?.status === 400) {
          return addToast({
            title: t("overview.apps.messages.actionBlocked"),
            message: response?.data.message,
            type: "error",
          });
        }
        return addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      });
  };

  return {
    registerApp,
    deleteApp,
    apps: apps || [],
    count: count || 0,
    androidOpen,
    iosOpen,
    isLoading,
    setAndroidOpen,
    setIosOpen,
    androidSelected,
    setAndroidSelected,
    iosSelected,
    setIosSelected,
    countAppsObserve,
    onRegisterApp,
    hasPrivileges,
  };
};
