// disable-eslint
import { GLCard, useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { GLSpotsMockProps } from "../../../../../mock/gl-spots";
import { CampaignDevice } from "../../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices.types";
import { useMessageCenterStore } from "../../../store/messageCenter";
import { useReview } from "../../ModalCreateCampaign/Review/useReview";
import { useSegmentation } from "../../ModalCreateCampaign/Segmentation/hooks/useSegmentation";
import {
  CreateCampaingStepNames,
  useCreateCampaignStore,
} from "../../ModalCreateCampaign/store/createCampaign";
import { useMessageContentStore } from "../../ModalCreateCampaign/store/messageContent";
import { CampaignCardsContainer } from "../onlineCampaignStyle";
import {
  IOnlineCampaignCard,
  IResponseCampaign,
} from "../types/onlineCampaigns.types";
import { useOnlineCampaignContent } from "./useOnlineCampaignContent";

export const Cards = ({ campaigns }: IOnlineCampaignCard) => {
  const { t } = useI18n();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { actions } = useMessageCenterStore();
  const { actions: createCampaignActions } = useCreateCampaignStore();
  const { notifications, loadingNotification, getCampaignInfo, hasPrivileges } =
    useOnlineCampaignContent();
  const { segmentationActions, spotListState } = useReview();
  const { handleOnAddSpotsToAudience } = useSegmentation();
  const { actions: messageContentActions } = useMessageContentStore();

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(" ")[0][0]}`,
    };
  };

  const campaignActions = (openModal?: boolean) => {
    // createCampaignActions.setModalType("edition");

    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>(
      "SEGMENTATION"
    );
    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>(
      "MESSAGE_CONTENT"
    );
    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>(
      "SCHEDULE"
    );
    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>("REVIEW");

    if (openModal) {
      setTimeout(() => {
        createCampaignActions.setModalSteperContentIsOpen(true);
      }, 500);
    }
  };

  const clickToEdit = (
    openModal: boolean,
    responseCampaign: IResponseCampaign | undefined
  ) => {
    if (!responseCampaign) return;
    campaignActions(openModal);

    const spotsId = responseCampaign.devices?.map(
      (device: CampaignDevice) => device?.device_id
    );

    const spotsAdded: GLSpotsMockProps[] = spotListState?.allGlSpots?.filter(
      (spot: GLSpotsMockProps) => spotsId?.includes(String(spot?.device_id))
    );

    segmentationActions.setCampaingName(responseCampaign.name || "");

    handleOnAddSpotsToAudience(spotsAdded, "to-audience-added");

    const notification = responseCampaign?.messages.map((messages) => messages);

    setTimeout(() => {
      if (!notification) return;
      messageContentActions.addNewNotifications(notification);
    }, 3000);
  };

  const formatScheduledIn = (index: number) => {
    let text = "";
    switch (campaigns[index]?.scheduled_in[0]?.type) {
      case "weekdays":
        text = t("messageCenter.list.card.scheduled_in.weekdays");
        break;

      case "everyday":
        text = t("messageCenter.list.card.scheduled_in.everyday");
        break;

      case "specifics_days":
        text = t("messageCenter.list.card.scheduled_in.specific_days");
        break;

      case "workday":
        text = t("messageCenter.list.card.scheduled_in.workday");
        break;

      case "weekend":
        text = t("messageCenter.list.card.scheduled_in.weekend");
        break;

      case undefined:
        text = t("messageCenter.list.card.scheduled_in.noInfo");
        break;
    }

    return text;
  };

  // useEffect(() => {
  //   if (state.cardInfo && state.cardInfo.name !== "") {
  //     clickToEdit(false);
  //   }
  // }, [state.cardInfo]);

  return (
    <CampaignCardsContainer>
      {campaigns?.map((campaign, index: number) => (
        <GLCard
          key={campaign.id}
          variant="sr-campaign-card"
          avatarName={stringAvatar(campaign.name).children}
          campaignName={campaign.name}
          createdDate={campaign.created_at}
          devicesCount={campaign.message_count}
          scheduledText={formatScheduledIn(index)}
          notificationsList={notifications?.messages || []}
          isLoading={loadingNotification}
          isPublished={campaign.is_published}
          isMobile={!isMobile}
          hasPermissionToDelete={hasPrivileges(["action_btn_delete_campaign"])}
          hasPermissionToEdit={hasPrivileges(["action_btn_edit_campaign"])}
          onClick={() => {
            actions.setCampaignId(campaign.id);
          }}
          onDeleteClick={() => {
            actions.setModalDeleteIsOpen(true);
          }}
          onEditClick={async () => {
            createCampaignActions.setModalType("edition");
            const responseCampaign = await getCampaignInfo(campaign.id);

            clickToEdit(true, responseCampaign);
          }}
        />
      ))}
    </CampaignCardsContainer>
  );
};
