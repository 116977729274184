import { GLSettingsApps } from "@group-link-one/grouplink-components";

import { PageAnimated } from "../../../utils/pageAnimated";
import { AppsContent } from "./Content/appContent";
import { useApp } from "./useApp";

export const Apps = () => {
  const options = useApp();

  return (
    <PageAnimated>
      <GLSettingsApps
        hasPermissionToCreate={options.hasPrivileges([
          "action_btn_add_new_app",
        ])}
        optionsToModalAppContent={options}
        optionsToModalDeleteApp={options}
      >
        <AppsContent />
      </GLSettingsApps>
    </PageAnimated>
  );
};
