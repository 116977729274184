import { useAuth } from "@group-link-one/gl-auth";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../../../Context/FlagsProvider";
import { useAppService } from "../../../Services/appService/useAppService";

export const useOverview = () => {
  const { countApps } = useAppService();
  const { hasPrivileges } = useFlags();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { data: count, isLoading } = useQuery({
    queryKey: ["count-apps"],
    queryFn: () => {
      if (!hasPrivileges(["apps_get"])) return;

      return countApps().then((res) => res);
    },
  });

  return {
    user,
    count,
    isLoading,
    navigate,
  };
};
